import styles from "./Cards.module.css";

function CardsComponent({ heading, text, image, color, index, length,...rest }) {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: color }}
      key={index}
      {...rest}
    >
      <img className={styles.image} src={image} alt="" style={{objectFit:length-1===index?'none':'cover'}}/>
      <div className={styles.cardDescription}>
        <h1 className={styles.heading}>{heading}</h1>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
}

export default CardsComponent;
